.header-cont {
    padding: 15px 0 15px 0;
    display: flex;
    flex-direction:initial;
}

.icon-logo {
    align-self: center;
    margin: auto;
}

.logo-header {
    width: 90px;
    height: 30px;
}