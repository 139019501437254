    .terms {
        text-align: center;
    }

    .select-cities-container {
        display: block;
        max-width: 700px;
        margin: auto;
    }

    .select-city-container {
        width: 90%;
        margin: auto;
    }

    .footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
     }

     .select-city-title-container {
        margin-bottom: 10px;
     }

     .footer-container {
        width: 100%;
     }