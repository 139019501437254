:root {
  --main-brand-color: #3b82f6;
  --main-brand-link-color: #1d4ed8;
  --main-brand-color-hover: #2563eb;
  --secondary-button-color: #ffffff;
  --gray-text-color: #4b5563;
  --secondary-button-border-color: var(--main-brand-color); 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}