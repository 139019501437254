.terms {
    text-align: center;
}

.select-product-container {
    display: block;
    max-width: 700px;
    margin: auto;
}

.select-products-container {

    width: 90%;
    margin: auto;
}


.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.select-city-title-container {
    margin-bottom: 10px;
}


.cards-container {
    display: flex;
    justify-content: center;
    margin: 40px 0 0 0;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
    width: 100%;
}

/**
nuevo
**/

.bd-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    align-items: center;
    gap: 2rem;
    cursor:pointer;
}

/*-- PAGES --*/
.title-shop {
    position: relative;
    margin: 0 2.5rem;
}

.title-shop::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 72px;
    height: 2px;
    background-color: var(--dark-color);
    margin-left: .25rem;
}

/*-- COMPONENT --*/
.card {
    box-shadow: 0 .5rem 1rem #D1D9E6;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem 2rem;
    border-radius: 1rem;
    overflow: hidden;
    height: 300px;
}

article:nth-child(1) {
    background-color: var(--first-color);
}

.card__img {
    width: 130px;
    height: 180px;
    padding: 0.5rem 0;
    transition: .5s;
}

.card__icon {
    font-size: 1.5rem;
    color: #fff;
}

.card__price {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.card__price_text {
    display: block;
    text-align: center;
}

.card__price_text--before {
    font-size: 1.10rem;
    margin-bottom: .25rem;
}

.card__price_text--now {
    font-size: var(--h3-font-size);
    font-weight: bold;
}

/*Move left*/
.card:hover {
    box-shadow: 0 .5rem 1rem #aeb4bd;
}

.button-action-product {
    margin: 0 35px 0 35px;
    padding: 0;
    display: flex;
}

.add {
    float: left;
}

.rem {
    float: right;
}

.cant {
    margin: auto;
}

.price {
    margin: auto;
    text-align: center;
}

.h1-1-price{
    line-height: 1;
    color: #111827;
    letter-spacing: -.025em;
    font-weight: 800;
}

.h1-3-w-price{
    font-size: 1.50rem;
}

.product-view-container {
    width: 90%;
    margin: auto;
    display: block;
}

.select-product-container {
    width: 90%;
    margin: auto;
}

.detail-product-container {
    width: 90%;
    margin: auto;
    max-width: 700px;
}

.card__img_detail {
    max-width: 400px;
    max-height: 600px;
    height: auto;
    width: auto;
    margin: auto;
    display: block;
}

.select-product-button {
    bottom: 30px;
    right: 50px;
    left: 50px;
}

.price-product-container {
    text-align: center;
    margin: 20px 0 20px 0;
}

.cant-product-text {
    font-size: 2.3rem;
}