.terms {
    text-align: center;
}

.select-modalities-container {
    display: block;
    max-width: 700px;
    margin: auto;
}

.select-modality-container {
    width: 90%;
    margin: auto;
}

.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.select-modality-title-container {
    margin-bottom: 10px;
}

.footer-container {
    width: 100%;
}

.select-modality-button {
    bottom: 30px;
    right: 50px;
    left: 50px;
}

.btn-1-modality {
    background-color: var(--main-brand-color);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    margin: 4px 2px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
}

.text-modality {
    width: 100%;
}

.btn-1-modality:hover {
    background-color: var(--main-brand-color-hover);
}

.btn-1-modality:active {
    transform: translateY(3px);
}