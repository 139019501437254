.terms {
    text-align: center;
}

.pick-up-modalities-container {
    display: block;
    max-width: 700px;
    margin: auto;
}

.pick-up-modality-container {
    width: 90%;
    margin: auto;
}

.cards-container {
    display: flex;
    justify-content: center;
    margin: 40px 0 0 0;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
    width: 100%;
}

.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.pick-up-modality-title-container {
    margin-bottom: 10px;
}

.footer-container {
    width: 100%;
}

.pick-up-modality-button {
    bottom: 30px;
    right: 50px;
    left: 50px;
}

.btn-1-modality {
    background-color: var(--main-brand-color);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    margin: 4px 2px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
}

.text-modality {
    width: 100%;
}

.btn-1-modality:hover {
    background-color: var(--main-brand-color-hover);
}

.btn-1-modality:active {
    transform: translateY(3px);
}

.office-status-icon {
    display: flex;
    align-items:center;
    width: 50%;
    justify-content: flex-end;
    
}

.office-status-title {
    display: flex;
    align-items: center;
    width: 50%;
}

.office-view-container {
    width: 90%;
    margin: auto;
    display: block;
}

.detail-office-container {
    width: 90%;
    margin: auto;
    max-width: 700px;
}

.office__img_detail {
    height: auto;
    width: auto;
    margin: auto;
    display: block;
    border-radius: 10px 100px / 100px;
    box-shadow: 10px -10px var(--main-brand-color);
}

.office__img_detail-container {
    margin: 50px 0 0 0;
}