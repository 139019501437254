.btn-1{
    background-color: var(--main-brand-color);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25rem;
    margin: 4px 2px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 0 0 #0000 , 0 0 #0000, 0 1px 3px 0 #0000001a,0 1px 2px 0 #0000000f;
}

.btn-1:hover{
    background-color: var(--main-brand-color-hover);
}

.btn-1:active{
  transform: translateY(3px);
}

.h1-1{
    line-height: 1;
    color: #111827;
    letter-spacing: -.025em;
    font-weight: 800;
}

.h1-3-w{
    font-size: 3.75rem;
}

.sp-1{
    display: block;
}

.text-1{
  color: var(--main-brand-color);
} 

.yaa {
  color: var(--main-brand-link-color);;
  margin: 12px;
  font-weight: 600;
}

.mt-3{
    margin-left: 0;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #6b7280;
    max-width: 36rem;
}

.bcv-logo {
  height: 100px;
}

.bcv-logo-container {
  text-align: center;
}

@media only screen and (max-width: 1283px) {
    .h1-3-w{
        font-size: 2.75rem;
    }
}

@media only screen and (max-width: 1023px) {
    .h1-3-w{
        font-size: 2.50rem;
    }
}

@media only screen and (max-width: 767px) {
    .h1-3-w{
        font-size: 2.10rem;
    }
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

::before,
::after {
  --aqd-content: '';
}

h1,
h2,
h3 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

h1,
h2,
h3,
p {
  margin: 0;
}

img {
  vertical-align: middle;
}

svg {
  display: block;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, ::before, ::after {
  --aqd-translate-x: 0;
  --aqd-translate-y: 0;
  --aqd-rotate: 0;
  --aqd-skew-x: 0;
  --aqd-skew-y: 0;
  --aqd-scale-x: 1;
  --aqd-scale-y: 1;
  --aqd-scroll-snap-strictness: proximity;
  --aqd-ring-offset-width: 0px;
  --aqd-ring-offset-color: #fff;
  --aqd-ring-color: rgb(59 130 246 / 0.5);
  --aqd-ring-offset-shadow: 0 0 #0000;
  --aqd-ring-shadow: 0 0 #0000;
  --aqd-shadow: 0 0 #0000;
  --aqd-shadow-colored: 0 0 #0000;
}

.a {
  width: 100%;
}

@media (min-width: 640px) {
  .a {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .a {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .a {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .a {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .a {
    max-width: 1536px;
  }
}

.b {
  position: absolute;
}

.c {
  position: relative;
}

.d {
  top: 2rem;
}

.e {
  bottom: 0px;
}

.f {
  bottom: 1.5rem;
}

.g {
  margin-left: auto;
  margin-right: auto;
}

.h {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.i {
  margin-top: 8rem;
}

.j {
  display: flex;
}

.k {
  display: grid;
}

.l {
  display: none;
}

.m {
  height: 1.5rem;
}

.n {
  height: 3rem;
}

.o {
  width: 66.666667%;
}

.p {
  width: 1.5rem;
}

.q {
  width: 3rem;
}

.r {
  width: 91.666667%;
}

.s {
  max-width: 24rem;
}

.t {
  max-width: 28rem;
}

.u {
  transform: translate(var(--aqd-translate-x), var(--aqd-translate-y)) rotate(var(--aqd-rotate)) skewX(var(--aqd-skew-x)) skewY(var(--aqd-skew-y)) scaleX(var(--aqd-scale-x)) scaleY(var(--aqd-scale-y));
}

@-webkit-keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.v {
  -webkit-animation: tw-bounce 1s infinite;
          animation: tw-bounce 1s infinite;
}

.w {
  align-content: center;
}

.x {
  align-items: center;
}

.y {
  justify-content: flex-start;
}

.z {
  justify-content: center;
}

._ {
  justify-items: center;
}

.aa {
  gap: 1rem;
}

.ba {
  gap: 2.5rem;
}

.ca {
  row-gap: 2rem;
}

.da > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--aqd-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--aqd-space-y-reverse));
}

.ea > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-x-reverse: 0;
  margin-right: calc(1rem * var(--aqd-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--aqd-space-x-reverse)));
}

.ga > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--aqd-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--aqd-space-x-reverse)));
}

.ha > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--aqd-space-y-reverse)));
  margin-bottom: calc(2rem * var(--aqd-space-y-reverse));
}

.ia > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--aqd-space-y-reverse)));
  margin-bottom: calc(1rem * var(--aqd-space-y-reverse));
}

.ja > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--aqd-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--aqd-space-y-reverse));
}

.ka > :not([hidden]) ~ :not([hidden]) {
  --aqd-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--aqd-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--aqd-space-y-reverse));
}

.la {
  overflow: hidden;
}

.ma {
  border-radius: 0.5rem;
}

.na {
  border-bottom-width: 1px;
}

.oa {
  --aqd-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--aqd-border-opacity));
}

.pa {
  --aqd-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--aqd-bg-opacity));
}

.qa {
  --aqd-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--aqd-bg-opacity));
}

.ra {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.sa {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.ta {
  padding-top: 3rem;
}

.ua {
  text-align: center;
}

.va {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.wa {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.xa {
  font-size: 1.5rem;
  line-height: 2rem;
}

.ya {
  font-size: 1rem;
  line-height: 1.5rem;
}

.za {
  font-size: 0.75rem;
  line-height: 1rem;
}

._a {
  font-weight: 600;
}

.ab {
  --aqd-text-opacity: 1;
  color: rgb(255 255 255 / var(--aqd-text-opacity));
}

.bb {
  --aqd-text-opacity: 1;
  color: rgb(75 85 99 / var(--aqd-text-opacity));
}

.cb {
  --aqd-text-opacity: 1;
  color: rgb(29 78 216 / var(--aqd-text-opacity));
}

.db {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.eb {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fb {
  --aqd-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --aqd-shadow-colored: 0 1px 3px 0 var(--aqd-shadow-color), 0 1px 2px -1px var(--aqd-shadow-color);
  box-shadow: var(--aqd-ring-offset-shadow, 0 0 #0000), var(--aqd-ring-shadow, 0 0 #0000), var(--aqd-shadow);
}

.gb {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hb {
  transition-duration: 200ms;
}

.ib {
  transition-duration: 500ms;
}

.jb {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hover\:kb:hover {
  --aqd-translate-y: -0.25rem;
  transform: translate(var(--aqd-translate-x), var(--aqd-translate-y)) rotate(var(--aqd-rotate)) skewX(var(--aqd-skew-x)) skewY(var(--aqd-skew-y)) scaleX(var(--aqd-scale-x)) scaleY(var(--aqd-scale-y));
}

.hover\:lb:hover {
  --aqd-scale-x: 1.25;
  --aqd-scale-y: 1.25;
  transform: translate(var(--aqd-translate-x), var(--aqd-translate-y)) rotate(var(--aqd-rotate)) skewX(var(--aqd-skew-x)) skewY(var(--aqd-skew-y)) scaleX(var(--aqd-scale-x)) scaleY(var(--aqd-scale-y));
}

.hover\:mb:hover {
  --aqd-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --aqd-shadow-colored: 0 10px 15px -3px var(--aqd-shadow-color), 0 4px 6px -4px var(--aqd-shadow-color);
  box-shadow: var(--aqd-ring-offset-shadow, 0 0 #0000), var(--aqd-ring-shadow, 0 0 #0000), var(--aqd-shadow);
}

@media (min-width: 640px) {
  .sm\:b {
    position: absolute;
  }

  .sm\:nb {
    left: 50%;
    right: 50%;
  }

  .sm\:ob {
    right: -8rem;
  }

  .sm\:pb {
    grid-column: span 1 / span 1;
  }

  .sm\:qb {
    display: block;
  }

  .sm\:rb {
    height: 27rem;
  }

  .sm\:sb {
    width: 50%;
  }

  .sm\:o {
    width: 66.666667%;
  }

  .sm\:tb {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:ba {
    gap: 2.5rem;
  }

  .sm\:ub {
    row-gap: 0px;
  }

  .sm\:vb {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .sm\:wb {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:xb {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 768px) {
  .md\:yb {
    right: -4rem;
  }

  .md\:j {
    display: flex;
  }

  .md\:zb {
    width: 33.333333%;
  }

  .md\:x {
    align-items: center;
  }

  .md\:_b {
    justify-content: space-between;
  }

  .md\:ba {
    gap: 2.5rem;
  }

  .md\:ha > :not([hidden]) ~ :not([hidden]) {
    --aqd-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--aqd-space-y-reverse)));
    margin-bottom: calc(2rem * var(--aqd-space-y-reverse));
  }

  .md\:ac > :not([hidden]) ~ :not([hidden]) {
    --aqd-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--aqd-space-y-reverse)));
    margin-bottom: calc(3rem * var(--aqd-space-y-reverse));
  }

  .md\:bc > :not([hidden]) ~ :not([hidden]) {
    --aqd-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--aqd-space-y-reverse)));
    margin-bottom: calc(0px * var(--aqd-space-y-reverse));
  }

  .md\:cc > :not([hidden]) ~ :not([hidden]) {
    --aqd-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--aqd-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--aqd-space-x-reverse)));
  }

  .md\:ra {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:dc {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:xa {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:ec {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:fc {
    right: 0px;
  }

  .lg\:gc {
    grid-column: span 2 / span 2;
  }

  .lg\:hc {
    width: 40%;
  }

  .lg\:ic {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:jc {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:kc {
    padding-top: 6rem;
  }

  .lg\:lc {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:gc {
    grid-column: span 2 / span 2;
  }

  .xl\:mc {
    margin-top: auto;
  }

  .xl\:nc {
    height: 100vh;
  }

  .xl\:oc {
    height: 100%;
  }

  .xl\:ic {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:pc {
    font-size: 4.5rem;
    line-height: 1;
  }
}

.logo-img{
  width: 150px;
  height:50px;
}